// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-wp-category-jsx": () => import("./../src/templates/wp-category.jsx" /* webpackChunkName: "component---src-templates-wp-category-jsx" */),
  "component---src-templates-wp-page-jsx": () => import("./../src/templates/wp-page.jsx" /* webpackChunkName: "component---src-templates-wp-page-jsx" */),
  "component---src-templates-wp-post-jsx": () => import("./../src/templates/wp-post.jsx" /* webpackChunkName: "component---src-templates-wp-post-jsx" */)
}

